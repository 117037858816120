export const AboutMe = () => {
    return (
        <>
            <p className='pr-32 pt-5'>
                Hello I'm Tom, a full-stack software developer with a concentration
                on single-page applications consuming Node-based APIs deployed in
                the cloud.
            </p>
            <br />
            <p>
                I graduated with honors from the University of California, Irvine
                where I earned a Bachelor’s degree in Biological Sciences. I first
                became interested in technology as a tool for data analysis, which
                later developed into a web development hobby, and finally into a
                professional goal as I completed projects and strengthened my
                skills.
            </p>
            <br />
            <p>
                While working as an educator, I leveraged several excellent
                resources to learn HTML, CSS, JavaScript, ReactJS, and AWS, which
                enabled me to develop and deploy a CMS/e-commerce site for my wife's
                business. My most recent fullstack project is a financial tracking
                application using React for the UI and consuming data from a GraphQL
                API and AWS DynamoDB.
            </p><br />
            <p>
                My immediate goals as a developer are to gain
                more experience with GraphQL, microservices, testing, and using
                CI/CD pipelines. I'm also interested in learn more about data visualization, 3d modeling, and animation in the browser.
            </p>

            <br />
            <p>
                Aside from techology, I enjoy cooking for my family, drinking hoppy IPAs, and watching combat sports.
            </p>

            <br />

            <p>
                As a builder, learner, and problem-solver by nature, I find software
                development deeply satisfying, and I look forward to continuing my
                growth in a fast-paced, collaborative, professional environment.
            </p>
        </>
    )
}