import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <div className='w-full fixed h-20 blur-filter z-20 shadow'>
      <div className='flex justify-end items-center p-5 h-full'>
        <nav>
          <ul className='flex'>
            <li className='mr-4'>
              <Link to='/'>
                <button>home</button>
              </Link>
            </li>
            <li className='mr-4'>
              <Link to='/about'>
                <button>about</button>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Nav;
