import React from 'react';
import Page from '../containers/Page';
import me from '../../images/avatar.jpg';
import { AboutMe } from '../../text/about';

const About = () => {
  return (
    <Page>
      <div className='pt-40 text-gray-400 mx-auto xs:w-full  sm:w-4/5 lg:w-2/5 text-justify'>
        <div className='relative border rounded border-blue-400 px-5 pb-5 card-shadow-fixed'>
          <img
            className='rounded-full w-20 absolute right-3 top-3'
            src={me}
            alt='me'
          />
          <AboutMe />
        </div>
      </div>
    </Page>
  );
};

export default About;
