

export const ersDesc = [
  'A business application used to process and track employee reimbursements.'
];

export const chasingDesc = [
  'An ecommerce site built for an entrepreneurial educator.'
]

export const plumaDesc = [
  'A mapping application that lets users query biological spatial data.'
]

export const mlDesc = [
  'External site showcasing my TensorFlow.js projects'
]

export const ChasingEnglishDescription = () => {
  return (
    <>
      <p>ChasingEnglish.com <s>is</s> was an ecommerce and content management system that I made for a middle school English teacher who, after spending most of her adult life teaching in a classroom, sought a new beginning as an entrepreneur. She also happened to be my wife.</p> <br />
      <p> The first iteration of this project was MERN stack hosted on Heroku, using a combination of Ant Design components and custom CSS for the UI, with a backend of Node-Express and Mongoose ORM, integrated with a MongoDB database.</p><br />
      <p>To reduce monthly costs and take advantage of AWS Cognito's rich user management system, I later rebuilt the site using AWS Amplify. The final stack was React and Tailwind CSS UI consuming a GraphQL API with AWS DynamoDB providing data persistence.
      </p>
    </>
  )
}

export const PlumaDescription = () => {
  return (
    <>
      <p>Pluma maps data from the Cornell Lab of Ornithology's massive database of bird sightings submitted by eBird users.</p><br />
      <p>
        The application uses 3 Google APIs to search for locations and display the data from eBird:
      </p>
      <ul style={{ listStyleType: 'circle', paddingLeft: '2rem' }}>
        <li ><b>Google Maps</b> to display geospatial data.</li>
        <li><b>Google Geocoding</b> to convert locations to geocoordinates.</li>
        <li><b>Google Directions</b> to draw routes from users' locations to bird sightings.</li>
      </ul><br />
      <p>As a former field biologist and perennial bird enthusiast, building Pluma was really, really fun because it combined multiple disciplines of interest of mine.</p>
    </>
  )
}

export const ErsDescription = () => {
  return (
    <>
      <p>
        This application tracks reimbursement requests made by employees. When submitting reimbursement requests, employees specify expense type, the amount spent, a description of the expense, and attach an image of a receipt. Managers can approve or deny employee requests, filter requests by employee, and view all of the employees' information.
      </p> <br />
      <p>The UI was built with React and a combination of Tailwind and custom CSS. The backend is a GraphQL API linked to a NoSQL database hosted in the cloud. </p><br />
      <p style={{ marginBottom: '1rem' }}>
        I leveraged AWS heavily for this project:
      </p>
      <ul style={{ listStyleType: 'circle', paddingLeft: '2rem' }}>
        <li><b>GrapQL API: </b>Amplify</li>
        <li><b>Authorization/authentication: </b>Cognito w/ Lambda for automatic User Pool assignment</li>
        <li><b>Data persistence: </b>DynamoDB</li>
        <li><b>Deployment and hosting: </b>Amplify</li>
      </ul>
    </>
  )
}
