import React from 'react';
import { Transition } from '@headlessui/react';

import Page from '../containers/Page';
import Button from '../Button';
import FlexContainer from '../containers/FlexContainer';
import SectionHeader from '../SectionHeader';

// noxid site imports
import chasingenglishMobile from '../../images/noxid/chasingenglish-mobile.png';
import chasingEnglishDesktop from '../../images/noxid/chasingenglish-desktop.png';

import { ChasingEnglishDescription } from '../../text/projectDesc';
import {
  GraphQl,
  ReactJs,
  TailwindCss,
  DynamoDb,
  Aws,
  Stripe,
} from '../icons/stackIcons';

const ChasingEnglish = (props) => {
  return (
    <Page>
      <FlexContainer tw='justify-around flex-wrap pt-40'>
        <div class='w-custom-40rem p-5'>
          <div className='flex items-center flex-wrap mb-3'>
            <SectionHeader text='Chasing English' tw='mr-5' />
            <Button
              label='offline 😔'
              action={() => null}
              mod='bg-blue-800 hover:none'
            />
          </div>
          <div class='text-justify text-gray-400 mb-3'>
            <ChasingEnglishDescription />
          </div>
          <div className='flex items-center justify-center mb-4'>
            <ReactJs width='25' height='25' />
            <TailwindCss height='30' width='25' />
            <GraphQl width='25' height='25' />
            <DynamoDb width='23' height='28' />
            <Aws height='24' width='34' />
            <Stripe height='25' width='50' />
          </div>
        </div>
        <article className='flex items-center w-custom-40rem'>
          <div className='ProjectSection___screenshots-container flex items-center'>
            <div className='screenshot-mobile-container'>
              <img
                className='screenshot-mobile'
                src={chasingenglishMobile}
                alt='Chasing English mobile'
              />
            </div>
            <div className='screenshot-tablet-container'>
              <img
                className='screenshot-tablet'
                src={chasingEnglishDesktop}
                alt='Chasing English desktop'
              />
            </div>
          </div>
        </article>
      </FlexContainer>
    </Page>
  );
};

export default ChasingEnglish;
