import React from 'react';

import Page from '../containers/Page';
import Button from '../Button';
import FlexContainer from '../containers/FlexContainer';
import SectionHeader from '../SectionHeader';

// noxid site imports
import ersMobile from '../../images/ers-mobile.png';
import ersDesktop from '../../images/ers-desktop.png';

import { ErsDescription } from '../../text/projectDesc';
import {
  GraphQl,
  ReactJs,
  TailwindCss,
  DynamoDb,
  Aws,
} from '../icons/stackIcons';

const Ers = (props) => {
  return (
    <Page>
      <FlexContainer tw='justify-center flex-wrap pt-40'>
        <div class='w-custom-40rem p-5'>
          <div className='flex items-center flex-wrap mb-3'>
            <SectionHeader text='Reimbursement Tracker' tw='mr-5' />
            <Button
              label='live site'
              action={() =>
                window.open('https://dev.d1qb2f0u1jpfr7.amplifyapp.com')
              }
            />
          </div>
          <div class='text-justify text-gray-400 mb-3'>
            <ErsDescription />
          </div>
          <div className='flex items-center justify-center mb-4'>
            <ReactJs width='25' height='25' />
            <TailwindCss height='30' width='25' />
            <GraphQl width='25' height='25' />
            <DynamoDb width='23' height='28' />
            <Aws height='24' width='34' />
          </div>
        </div>
        <article className='flex items-center w-custom-40rem'>
          <div className='ProjectSection___screenshots-container flex items-center'>
            <div className='screenshot-mobile-container-ers'>
              <img
                className='screenshot-mobile-ers'
                src={ersMobile}
                alt='Chasing English mobile'
              />
            </div>
            <div className='screenshot-tablet-container'>
              <img
                className='screenshot-tablet'
                src={ersDesktop}
                alt='Chasing English desktop'
              />
            </div>
          </div>
        </article>
      </FlexContainer>
    </Page>
  );
};

export default Ers;
