import React, { useState, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useSpring, animated } from '@react-spring/three';

function Icos() {
  const Shape = () => {
    const meshRef = useRef();
    const [hover, setHover] = useState(false);

    const { scale } = useSpring({
      scale: hover ? 1.2 : 1,
    });

    useFrame(
      (state, delta) => (
        (meshRef.current.rotation.x += 0.001),
        (meshRef.current.rotation.y -= 0.005)

        // (meshRef.current.position.x += 0.001),
        // (meshRef.current.position.y -= 0.005)
      )
    );

    return (
      <animated.mesh
        scale={scale}
        ref={meshRef}
        onPointerOver={() => {
          setHover(true);
        }}
        onPointerOut={() => {
          setHover(false);
        }}
      >
        <icosahedronBufferGeometry attach='geometry' args={[1.5]} />
        <meshBasicMaterial
          attach='material'
          color='#90cdf4'
          wireframe={true}
          transparent={hover ? false : true}
          opacity={hover ? 1 : 0.2}
        />
      </animated.mesh>
    );
  };

  return (
    <div>
      <Canvas>
        <Shape />
      </Canvas>
    </div>
  );
}

export default Icos;
