import React from 'react';
import Button from './Button';
// import { GraphQl } from './icons/stackIcons';

const ProjectCard = ({ imageSrc, projectName, description, action }) => {
  return (
    <div className='max-w-xs mx-auto overflow-hidden rounded bg-transparent card-shadow relative mb-10 pb-8'>
      <div>
        <img
          className='object-cover object-center w-full h-56 '
          src={imageSrc}
          alt='avatar'
        />
      </div>

      <div className='px-6 py-4'>
        <h1 className='text-xl font-semibold'>{projectName}</h1>

        <div className='py-2'>
          {description.map((desc, i) => (
            <p key={i}>{desc}</p>
          ))}
        </div>
      </div>
      <div className='flex items-center mt-4 absolute bottom-4 right-4'>
        <Button label='Learn more' action={() => action()} />
      </div>
    </div>
  );
};

export default ProjectCard;
