import React from 'react';

const Button = ({ label, action, mod }) => {
  return (
    <button
      onClick={() => action()}
      className={`rounded py-1 px-3 border-solid border-2 text-xs border-blue-200 shadow hover:text-white hover:bg-blue-800 ${mod}`}
    >
      <div>{label}</div>
    </button>
  );
};

export default Button;
