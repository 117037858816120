import React from 'react';

const Section = (props) => {
  return (
    <section id={props.id} className={`min-h-screen ${props.tw}`}>
      {props.children}
    </section>
  );
};

export default Section;
