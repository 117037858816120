import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

import Page from '../containers/Page';
import Button from '../Button';
import FlexContainer from '../containers/FlexContainer';
import SectionHeader from '../SectionHeader';

// noxid site imports
import plumaMobile from '../../images/noxid/pluma-mobile.png';
import plumaDesktop from '../../images/noxid/pluma-desktop.png';
import { JavaScript, JQuery, Sass } from '../icons/stackIcons';

import { PlumaDescription } from '../../text/projectDesc';

const Pluma = () => {
  return (
    <Page>
      <FlexContainer tw='justify-around flex-wrap pt-40'>
        <div class='w-custom-40rem p-5'>
          <div className='flex items-center flex-wra mb-3'>
            <SectionHeader text='pluma' tw='mr-5' />
            <Button
              label='live site'
              action={() =>
                (window.location.href = 'https://chefthomas.github.io/pluma/')
              }
            />
          </div>
          <div class='mb-3'></div>
          <div class='text-justify text-gray-400 mb-3'>
            <PlumaDescription />
          </div>
          <div className='flex items-center justify-center mb-4'>
            <JavaScript width='25' height='30' />
            <JQuery height='20' />
            <Sass height='20' />
          </div>
        </div>
        <article className='flex items-center w-custom-40rem'>
          <div className='ProjectSection___screenshots-container flex items-center'>
            <div className='screenshot-mobile-container'>
              <img
                className='screenshot-mobile'
                src={plumaMobile}
                alt='Chasing English mobile'
              />
            </div>
            <div className='screenshot-tablet-container'>
              <img
                className='screenshot-tablet'
                src={plumaDesktop}
                alt='Chasing English desktop'
              />
            </div>
          </div>
        </article>
      </FlexContainer>
    </Page>
  );
};

export default Pluma;
