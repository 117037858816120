import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import Page from '../containers/Page';
import Button from '../Button';
import SectionHeader from '../SectionHeader';
import Section from '../containers/Section';
import ProjectCard from '../ProjectCard';
import Icos from '../Icos';
import ers from '../../images/ers.png';
import chasingEnglish from '../../images/chasing-english-new.png';
import pluma from '../../images/pluma-splash.png';
import ml from '../../images/ml.png';

import {
  chasingDesc,
  ersDesc,
  plumaDesc,
  mlDesc,
} from '../../text/projectDesc';

const Home = () => {
  const email = useRef(null);
  const history = useHistory();

  function copyToClipboard(e) {
    navigator.clipboard.writeText('ornithopterpilot@gmail.com');
    alert('ornithopterpilot@gmail.com copied to clipboard');
  }

  return (
    <Page>
      <Section tw={'min-h-screen pt-32'}>
        <div className="flex items-center space-justify-between flex-wrap">
          <div className="w-1/2 flex items-center">
            <h1 className="text-xl sm:text-2xl tracking-wider">
              Tom Dixon
              <p className="text-blue-200 pl-1">Fullstack Developer</p>
              <span
                ref={email}
                onClick={(e) => copyToClipboard(e)}
                className="text-sm pl-2 cursor-pointer"
              >
                ornithopterpilot@gmail.com
              </span>
            </h1>
            <Icos />
          </div>
        </div>
        <hr className="mb-4 w-40" />
        <p className="text-blue-100 w-1/3 mb-10 text-lg">
          Seattle-based software developer, specializing in React, Node, and
          cloud deployment.
        </p>
        <Link to="projects" smooth={true}>
          <Button label="Projects" action={() => console.log('clicked')} />
        </Link>
      </Section>
      <Section id="projects" tw={'min-h-screen pt-20'}>
        <SectionHeader text="Projects" tw=" pb-20" />
        <div className="flex flex-wrap gap-4 justify-evenly">
          <ProjectCard
            projectName="Reimbursement Tracker"
            imageSrc={ers}
            description={ersDesc}
            action={() => history.push('/projects/ers')}
          />
          <ProjectCard
            projectName="Chasing English"
            imageSrc={chasingEnglish}
            description={chasingDesc}
            action={() => history.push('/projects/chasing-english')}
          />
          <ProjectCard
            projectName="Pluma"
            imageSrc={pluma}
            description={plumaDesc}
            action={() => history.push('/projects/pluma')}
          />
          <ProjectCard
            projectName="Machine Learning"
            imageSrc={ml}
            description={mlDesc}
            action={() =>
              window.open('https://dev.dabo6oebgje2x.amplifyapp.com/')
            }
          />
        </div>
      </Section>
    </Page>
  );
};

export default Home;
