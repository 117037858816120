
import './App.css';
import Nav from './components/Nav'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './components/pages/Home'
import ChasingEnglish from './components/pages/ChasingEnglish'
import Ers from './components/pages/Ers.jsx'
import Pluma from './components/pages/Pluma'
import About from './components/pages/About'

function App() {
  return (
    <Router>
      <Nav />
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/projects/chasing-english">
        <ChasingEnglish display={true} />
      </Route>
      <Route exact path="/projects/ers">
        <Ers />
      </Route>
      <Route exact path="/projects/pluma">
        <Pluma />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
    </Router>
  );
}

export default App;
